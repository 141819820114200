import { useState, useEffect } from 'react'
import { BigNumber } from 'ethers'
import { useConfig, useEthers, useBlockNumber, useEtherBalance } from '@usedapp/core'
import { useKlubBalance, useTombBalance, useSerumBalance, useKlubsPerSerum, useEthPerSerum, useMaxTokens, useTotalSupply, useMintWithKLUB, useMintWithETH } from '../../hooks'
import { Transactions } from '../notifications/Transactions'
import Stats from './Stats'

global.Buffer = global.Buffer || require('buffer').Buffer

function Main() {

    const { activateBrowserWallet, account } = useEthers()
    const { networks } = useConfig()
    const blockNumber = useBlockNumber()
    const klubsPerSerum = useKlubsPerSerum()
    const ethPerSerum = useEthPerSerum()
    const ethBalance = useEtherBalance(account)
    const klubBalance = useKlubBalance(account)
    const tombBalance = useTombBalance(account)
    const serumBalance = useSerumBalance(account)
    const maxTokens = useMaxTokens()
    const totalSupply = useTotalSupply()
    const serumAvailable = totalSupply && maxTokens && totalSupply.lt(maxTokens)

    const { mintWithKLUB, mintWithKLUBState } = useMintWithKLUB()
    const [mintWithKLUBErrorMessage, setMintWithKLUBErrorMessage] = useState()
    const { mintWithETH, mintWithETHState } = useMintWithETH()
    const [mintWithETHErrorMessage, setMintWithETHErrorMessage] = useState()

    const [errorMessage, setErrorMessage] = useState()
    const [valWithKLUB, setValWithKLUB] = useState(1)
    const [valWithETH, setValWithETH] = useState(1)

    useEffect(() => {
        console.log("ethPerSerum", ethPerSerum && ethPerSerum.toString())
        console.log("valWithETH", valWithETH)
        console.log("eth cost", ethPerSerum && ethPerSerum.mul(valWithETH || 0).div(BigNumber.from("10").pow(14)).toNumber().toFixed(4) / 10000)
    }, [ethPerSerum, valWithETH])

    const mintWithKLUBBtnClick = async () => {
        setMintWithKLUBErrorMessage()
        if (valWithKLUB && valWithKLUB > 0 && valWithKLUB <= 20) {
            try {
                await mintWithKLUB(valWithKLUB)
                    .catch((e) => {
                        console.log(e)
                        if (e) {
                            setMintWithKLUBErrorMessage(e.message)
                        }
                    })
            } catch (e) {
                setMintWithKLUBErrorMessage(e.message)
            }
        } else {
            setMintWithKLUBErrorMessage("Enter number between 1 and 20")
        }
    }

    const mintWithETHBtnClick = async () => {
        setMintWithETHErrorMessage()
        if (valWithETH && valWithETH > 0 && valWithETH <= 20) {
            try {
                await mintWithETH(valWithETH, { value: ethPerSerum.mul(valWithETH) })
                    .catch((e) => {
                        console.log(e)
                        if (e) {
                            setMintWithETHErrorMessage(e.message)
                        }
                    })
            } catch (e) {
                setMintWithETHErrorMessage(e.message)
            }
        } else {
            setMintWithETHErrorMessage("Enter number between 1 and 20")
        }
    }

    useEffect(() => {
        if (mintWithKLUBState && mintWithKLUBState.status === 'Exception') {
            setMintWithKLUBErrorMessage(mintWithKLUBState.errorMessage)
            console.log(mintWithKLUBState)
        }
    }, [mintWithKLUBState])

    useEffect(() => {
        if (mintWithETHState && mintWithETHState.status === 'Exception') {
            setMintWithETHErrorMessage(mintWithETHState.errorMessage)
            console.log(mintWithETHState)
        }
    }, [mintWithETHState])



    useEffect(() => {
        window.ethereum && window.ethereum.on('chainChanged', (chainId) => {
            if (chainId !== '0x' + networks[0].chainId) {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
            }
        })
    }, [networks])

    const onConnectWallet = async () => {
        setErrorMessage()
        activateBrowserWallet(async (er) => {
            if (er) {
                console.log(er)
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
                activateBrowserWallet()
            }
        }, false)
    }

    return (
        <main className="bg-white sm:max-w-[1024px] mx-auto flex rounded-xl py-6 px-4 my-8 drop-shadow flex-col items-center h-full">
            {!blockNumber && <div className="absolute z-10 w-full h-full backdrop-blur-sm"></div>}
            <div className="w-full">
                {!account &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={onConnectWallet}>Connect Wallet</button>}
                {account &&
                    <div>
                        <Stats blockNumber={blockNumber}
                            klubBalance={klubBalance}
                            ethBalance={ethBalance}
                            tombBalance={tombBalance}
                            serumBalance={serumBalance}
                            maxTokens={maxTokens}
                            totalSupply={totalSupply}
                        />

                        <div>
                            <div className="rounded-xl p-10 m-auto overflow-hidden">
                                <img className="m-auto w-[750px]" src="/tube.001.jpg" alt="Serums" />
                            </div>
                        </div>
                        {serumAvailable && <>
                            {/* <h2 className="my-1 text-lg font-bold text-pink-500">{BigNumber.isBigNumber(totalSupply) && totalSupply.toString()}/{BigNumber.isBigNumber(MAX_TOKENS) && MAX_TOKENS.toString()} AT {price && utils.formatEther(price)} EACH</h2>
                        <div className="mb-0 text-base text-blue-400" style={{ fontFamily: "RobotoMedium" }} >Max {BigNumber.isBigNumber(MAX_PER_MINT) && MAX_PER_MINT.toString()} per transaction</div>
                    {account && presaleStarted && !publicSaleStarted && !isSoldOut && <div className="mb-0 text-base text-blue-400" style={{ fontFamily: "RobotoMedium" }}>6 presale mint per wallet</div>} */}
                            <h5 className="mt-4 text-xl text-center text-gray-400">Mint with {ethPerSerum && ethPerSerum.div(BigNumber.from("10").pow(14)).toNumber().toFixed(4) / 10000} ETH each</h5>
                            <form className="w-full">
                                <div>
                                    <div>
                                        <label htmlFor="quant" className="text-lg block font-medium leading-10 text-center text-gray-500 overflow-ellipsis">Enter quantity:</label>
                                        <input type="number" min="1" max={ethBalance && ethPerSerum && ethBalance.div(ethPerSerum).toString()} onFocus={(e) => e.target.select()} value={valWithETH} onChange={(e) => { setValWithETH(e.target.value) }} name="quantity" autoFocus className="w-full py-2 mb-4 text-lg font-bold text-center text-blue-600 border outline-none" />
                                    </div>
                                </div>
                                {account && ethBalance && ethPerSerum && ethBalance.gte(ethPerSerum.mul(valWithETH || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-emerald-500 rounded hover:bg-emerald-600 active:bg-emerald-500 disabled:bg-emerald-300 disabled:cursor-default" onClick={async () => await mintWithETHBtnClick().catch((e) => console.log("mint error", e))}>Mint with {ethPerSerum && ethPerSerum.mul(valWithETH || 0).div(BigNumber.from("10").pow(14)).toNumber().toFixed(4) / 10000} ETH</button>}
                                {account && ethBalance && ethPerSerum && ethBalance.lt(ethPerSerum.mul(valWithETH || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Insuffucient ETH</button>}
                                {mintWithETHErrorMessage && <h5 className="text-pink-800">{mintWithETHErrorMessage}</h5>}
                            </form>
                            <h5 className="mt-4 text-xl text-center text-gray-400">Mint with {klubsPerSerum && klubsPerSerum.div(BigNumber.from("10").pow(18)).toString()} $KLUB each</h5>
                            <form className="w-full">
                                <div>
                                    <div>
                                        <label htmlFor="quant" className="text-lg block font-medium leading-10 text-center text-gray-500 overflow-ellipsis">Enter quantity:</label>
                                        <input type="number" min="1" max={klubBalance && klubsPerSerum && klubBalance.div(klubsPerSerum).toString()} onFocus={(e) => e.target.select()} value={valWithKLUB} onChange={(e) => { setValWithKLUB(e.target.value) }} name="quantity" autoFocus className="w-full py-2 mb-4 text-lg font-bold text-center text-blue-600 border outline-none" />
                                    </div>
                                </div>
                                {account && klubsPerSerum && klubBalance && klubBalance.gte(klubsPerSerum.mul(valWithKLUB || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-amber-500 rounded hover:bg-amber-600 active:bg-amber-500 disabled:bg-amber-300 disabled:cursor-default" onClick={async () => await mintWithKLUBBtnClick().catch((e) => console.log("mint error", e))}>Mint with {klubsPerSerum && klubsPerSerum.mul(valWithKLUB || 0).div(BigNumber.from("10").pow(18)).toString()} $KLUB</button>}
                                {account && klubsPerSerum && klubBalance && klubBalance.lt(klubsPerSerum.mul(valWithKLUB || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Insuffucient $KLUB</button>}
                                {mintWithKLUBErrorMessage && <h5 className="text-pink-800">{mintWithKLUBErrorMessage}</h5>}
                            </form>
                            {/* <h5 className="mt-4 text-xl text-center text-gray-400">Claim</h5>
                            {canClaimTombs && tombClaimed === false && <button type="button" className="w-full px-5 py-3 mb-8 mt-2 font-semibold text-center text-white bg-cyan-500 rounded hover:bg-cyan-600 active:bg-cyan-500 disabled:bg-cyan-300 disabled:cursor-default" onClick={async () => await claimBtnClick().catch((e) => console.log("claim error", e))}>Claim {canClaimTombs} Tombstones</button>}
                            {canClaimTombs && tombClaimed && <button type="button" className="w-full px-5 py-3 mb-8 mt-4 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Tombstones Already Claimed</button>}
                            {claimErrorMessage && <h5 className="text-pink-800">{claimErrorMessage}</h5>} */}
                        </>}
                        {maxTokens && !serumAvailable && <button type="button" className="w-full px-5 py-3 mb-8 mt-4 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>All Serums has been minted!</button>}
                        {/* {maxTokens && serumBalance && serumBalance.gte(1) && <div>
                            <h5 className="mt-4 text-xl text-center text-gray-400">Apply Serum to Tomb</h5>
                            <form className="w-full">
                                <div className="w-full grid grid-cols-2">
                                    <select class="form-select appearance-none block w-full px-3
                                        py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat
                                        border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        aria-label="Default select example">
                                        <option selected>Select Serum</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <select class="form-select appearance-none block w-full px-3
                                        py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat
                                        border border-solid border-gray-300 rounded transition ease-in-out m-0
                                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                        aria-label="Default select example">
                                        <option selected>Select Tomb</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                {account && klubsPerSerum && klubBalance && klubBalance.gte(klubsPerSerum.mul(valWithKLUB || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-amber-500 rounded hover:bg-amber-600 active:bg-amber-500 disabled:bg-amber-300 disabled:cursor-default" onClick={async () => await mintWithKLUBBtnClick().catch((e) => console.log("mint error", e))}>Mint with {klubsPerSerum && klubsPerSerum.mul(valWithKLUB || 0).div(BigNumber.from("10").pow(18)).toString()} $KLUB</button>}
                                {account && klubsPerSerum && klubBalance && klubBalance.lt(klubsPerSerum.mul(valWithKLUB || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Insuffucient $KLUB</button>}
                                {mintWithKLUBErrorMessage && <h5 className="text-pink-800">{mintWithKLUBErrorMessage}</h5>}
                            </form>
                        </div>
                        } */}
                    </div>
                }
            </div>
            <span className="mt-3 text-gray-500" style={{ fontFamily: "RobotoMedium" }}>Current block: {blockNumber}</span>
            <div className='w-full mt-3 text-gray-500' style={{ fontFamily: "RobotoMedium" }}>
                {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                <div className='w-full text-blue-400'>{<Transactions />}</div>
            </div>
        </main>
    )
}


export default Main;