import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { call, makeUseBalance} from './hooks'
import abijson from './abis/Serum.json'
import { serumContractAddress } from '../config'

const contractInterface = new utils.Interface(abijson.abi)
const contract = new Contract(serumContractAddress, contractInterface)

const contractCall = call(contractInterface, serumContractAddress)

const useSerumBalance = makeUseBalance(contractCall)

const useKlubsPerSerum = () => {
    const [klubsPerSerum] = useContractCalls(
        [contractCall('KLUBS_PER_SERUM')]
    ) ?? []
    return klubsPerSerum?.[0]
}

const useEthPerSerum = () => {
    const [price] = useContractCalls(
        [contractCall('price')]
    ) ?? []
    return price?.[0]
}

const useMaxTokens = () => {
    const [maxTokens] = useContractCalls(
        [contractCall('MAX_TOKENS')]
    ) ?? []
    return maxTokens?.[0]
}

const useTotalSupply = () => {
    const [totalSupply] = useContractCalls(
        [contractCall('totalSupply')]
    ) ?? []
    return totalSupply?.[0]
}

const useMintWithKLUB = () => {
    const fn = useContractFunction(contract, 'mintWithKLUB')
    return { mintWithKLUB: fn.send, mintWithKLUBState: fn.state, mintWithKLUBEvents: fn.events }
}

const useMintWithETH = () => {
    const fn = useContractFunction(contract, 'mint')
    return { mintWithETH: fn.send, mintWithETHState: fn.state, mintWithETHEvents: fn.events }
}

const useApplyToTomb = () => {
    const fn = useContractFunction(contract, 'applyToTomb')
    return { applyToTomb: fn.send, applyToTombState: fn.state, applyToTombEvents: fn.events }
}

export { useSerumBalance, useKlubsPerSerum, useEthPerSerum, useMaxTokens, useTotalSupply, useMintWithKLUB, useMintWithETH, useApplyToTomb }