import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { call, makeUseBalance} from './hooks'
import tomb from './abis/Tombstone.json'
import { tombContractAddress } from '../config'

const tombInterface = new utils.Interface(tomb.abi)
const tombContract = new Contract(tombContractAddress, tombInterface)

const tombCall = call(tombInterface, tombContractAddress)

const useTombBalance = makeUseBalance(tombCall)

const useTombClaimed = (address) => {
    const [hasClaimed] = useContractCalls(
        [address && tombCall('tombClaimed', [address])]
    ) ?? []
    return hasClaimed?.[0]
}

const useKlubsPerTomb = () => {
    const [klubsPerTomb] = useContractCalls(
        [tombCall('KLUBS_PER_TOMB')]
    ) ?? []
    return klubsPerTomb?.[0]
}

const useMaxTokens = () => {
    const [maxTokens] = useContractCalls(
        [tombCall('MAX_TOKENS')]
    ) ?? []
    return maxTokens?.[0]
}

const useTotalSupply = () => {
    const [totalSupply] = useContractCalls(
        [tombCall('totalSupply')]
    ) ?? []
    return totalSupply?.[0]
}

const useMintWithKLUB = () => {
    const fn = useContractFunction(tombContract, 'mintWithKLUB')
    return { mintWithKLUB: fn.send, mintWithKLUBState: fn.state, mintWithKLUBEvents: fn.events }
}

const useClaim = () => {
    const fn = useContractFunction(tombContract, 'claim')
    return { claim: fn.send, claimState: fn.state, claimEvents: fn.events }
}

export { useTombBalance, useTombClaimed, useKlubsPerTomb, useMaxTokens, useTotalSupply, useMintWithKLUB, useClaim }